#CollectionManager .main {
    background-color: var(--light-gray-background);
    padding-top: 10vh;
    min-height: 100dvh;
}

#CollectionManager * {
    box-sizing: border-box !important;
}

#CollectionManager .new-deck {
    height: 10vh;
    text-align: center;
}

#CollectionManager .new-deck h1 {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0.5vh;
    font-size: 2.5vh;
}

#CollectionManager .deck {
    margin-bottom: 0vh !important;
}

#CollectionManager .deck-edit-icon {
    position: absolute;
    bottom: 1.25vh;
    right: 1vh;
    width: 3.5vh !important;
    height: 3.5vh !important;
    padding: 0.25vh;
    opacity: 0.7;
    z-index: 5;
}

#CollectionManager .deck:hover .deck-edit-icon {
    opacity: 1;
}

#CollectionManager .deckCol {
    overflow: visible;
    padding: 0;
    margin-top: 1vh;
    border-radius: var(--Main-UI-border-radius);
}

#CollectionManager .duplicate-delete-row {
    display: flex;
    margin-top: 1vh;
}

#CollectionManager .duplicate-delete-row button {
    margin: 0;
    flex-grow: 2;
}

#CollectionManager .home-title h1 {
    font-size: 4vh;
    font-weight: var(--semi-bold-font-weight);
}

#CollectionManager .home-title p {
    margin-bottom: 0;
}