.fullscreen-panel-content {
    z-index: 1000;
    width: 100vw;
    position: fixed;
    top: 10vh;
    top: 10dvh;
    color: white !important;
}

.fullscreen-panel-content>div {
    overflow-y: auto;
}
/*
.fullscreen-panel-content button {
    flex-shrink: 0;
    border-radius: 1.5vh;
    overflow: hidden;
    padding: 0;
    border: 0;
    margin: 1.5vh;
}
*/
.dismissArea {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    opacity: 0;
    z-index: 1000;
}

.selected-deck>img {
    
}