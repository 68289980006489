#Game .lifeCounters{
    width: 25vh;
    height: 60dvh;
    position: fixed;
    display: flex;
    flex-direction: column;
    right: 1vw;
    top: 15vh;
    border-radius: 1vw;
    overflow: hidden;
    z-index: 5;
}

#GameReborn .zoomed .lifeCounters {
    position: relative;
    z-index: 1100;
}

#GameReborn .lifeCounters {
    width: 20vh;
    height: calc(var(--bottom-section-card-height) - 2vh);
    display: flex;
    flex-direction: column;
    border-radius: 1vw;
    overflow: hidden;
    z-index: 5;
    margin-right: 1vh;
    margin: 1vh;
}

.lifeCounter {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
}

.hide-horde-lp .hordeLife {
    display: none;
}

.lifeCounter p {
    position: absolute;
}

.lifeCounter .name {
    top: 18%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: var(--small-text-size);
}

.lifeCounter .total {
    top: 36%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: var(--extra-large-text-size);
    font-weight: var(--semi-bold-font-weight);
}

.lifeCounter .variation {
    top: 0vh;
    left: 15%;
    transform: translate(-50%, -50%);
    font-size: var(--large-text-size);
}

.hordeLife {
    background-color: rgba(0,0,0,0.2);
}

.life-upAndDown {
    height: 100%;
    width: 25vh;
    position: absolute;
    z-index: 10;
}

.life-upAndDown>div:hover {
    opacity: 0.3;
    background-color: rgba(255, 255, 255, 0.247);
    cursor: pointer;
}

.life-up {
    height: 50%;
}

.life-down {
    height: 50%;
}

.adding .life-up {
    opacity: 1 !important;
    background-color: rgba(255, 255, 255, 0.247);
}

.removing .life-down {
    opacity: 1 !important;
    background-color: rgba(255, 255, 255, 0.247);
}