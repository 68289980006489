/*-------- OPTIONS --------*/

.options {
    height: 80dvh;
    max-height: 80vh;
    margin-bottom: 0;
    text-align: left;
    display: flex;
    flex-direction: column;
    padding: 2vh;
    padding: 2dvh;
    /*border-radius: var(--Main-UI-border-radius);*/
    overflow-x: hidden;
    overflow-y: auto;
    /*background-color: var(--gray-background);*/
}

.options h1 {
    margin-top: 0;
    margin-bottom: 1vh;
    margin-bottom: 1dvh;
    width: 100%;
    font-weight: var(--bold-font-weight);
    font-size: var(--extra-large-text-size);
}

.options h3:first-child {
    margin-top: 0;
}

.options h3 {
    font-weight: var(--semi-bold-font-weight);
    margin-bottom: 3vh;
    margin-top: 4vh;
}

.options h4 {
    margin: 0;
}

.options .sub-option label {
    margin: 0;
}

.options p>i {
    font-size: max(1.5dvh, 10px);
    width: 100%;
}

.options-categories {
    /*border-bottom: 1px solid white;*/
}

.options-categories h2 {
    font-size: var(--large-text-size);
    font-weight: var(--semi-bold-font-weight);
    padding-bottom: 2vh;
    margin: 0;
    width: 100%;
    font-size: 3vh;
    cursor: pointer;
    border-bottom: 0.7vh solid transparent;
    transition: all 0.3s ease-in-out;
}

.options-categories h2.selected {
    border-bottom: 0.7vh solid white;
}

.options p {
    font-size: var(--small-text-size);
    font-weight: var(--regular-font-weight);
    margin-bottom: 1dvh;
    margin-top: 0;
    width: 100%;
}

.options label {
    font-size: var(--small-text-size);
    font-weight: var(--regular-font-weight);
    margin-bottom: 2vh;
    margin-bottom: 2dvh;
    width: 100%;
}

.options div {
    margin-bottom: 2vh;
    margin-bottom: 2dvh;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

.options div:last-child {
    /*margin-bottom: 0vh;*/
}

.options .sub-option {
    display: flex;
    flex-direction: column;
    border-left: 1px solid white;
    padding-left: 2vh;
    margin-bottom: 2.5dvh;
    width: 100%;
}

.options .form-control {
    width: calc(100% - 1.5rem);
    margin-bottom: 1vh;
    font-size: var(--small-text-size);
}

.options .background-image-preview {
    margin-bottom: 3vh;
}

.options img.background-image-preview {
    width: 100%;
    height: 20vh;
    object-fit: cover;
    border-radius: var(--Main-UI-border-radius);
}

.options .multiplayer-link {
    padding: 0.8vh;
    border-radius: 1.5vh;
    cursor: pointer;
    transition: all ease-in-out 0.3s;
}

.options .multiplayer-link:hover {
    background-color: black !important;
}

.patreon-restricted {
    opacity: 0.5;
    pointer-events: none;
}