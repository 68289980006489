.App {
  text-align: center;
  background-color: black;
}
/*
html, body, .foreground, .vignette, .background {
  //width: 100vw;
  height: 100dvh;
  overflow: hidden;
}
body {
  position: relative;
  position: fixed;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
*/
.App-header {
  background-color: var(--gray-background);
  /*min-height: 100vh;
  min-height: 100dvh;*/
  /*height: 100vh;
  height: 100dvh;
  max-height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;*/
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

hr.mt-4 {
  margin: 1vh 0 !important;
  margin-top: 1vh !important;
}

.defaultButton {
  color: white;
  background-color: black;
  font-size: 2vw;
}

@media (min-width: 1600px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 78vw !important;
  }
}
