* {
    box-sizing: border-box;
}

#app {
    height: 100%;
    display: flex;
    justify-content: center;
}

.login-panel .container>header {
    font-size: 1.3rem;
    font-weight: 700;
    margin: 0 auto 6dvh auto;
    position: relative;
    height: 25px;
    overflow: hidden;
    margin-top: 10vh;
}

@media only screen and (orientation:portrait) {
    .login-panel .container>header {
        margin-top: 0;
    }
}

.login-panel .header-headings {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.login-panel .header-headings>span {
    margin: 0.5vh 0;
    font-size: 2.5vh;
    font-weight: var(--semi-bold-font-weight);
    text-transform: uppercase;
    color: white;
    letter-spacing: 0.1vh;
}

.login-panel .header-headings.sign-in {
    transform: translateY(-1.25vh);
}

.login-panel .header-headings.sign-up {
    transform: translateY(-5.4vh);
}

.login-panel .header-headings.forgot {
    transform: translateY(-10.3vh);
}

@media screen and (max-width: 380px) {
    .login-panel .login-options {
        width: 100%;
    }
}

.login-panel input:disabled {
    display: none;
}

.login-panel .login-options>li {
    cursor: pointer;
    opacity: .5;
    transition: all .2s ease;
    list-style-type: none;
    font-size: var(--small-text-size);
    font-weight: var(--semi-bold-font-weight);
    text-transform: uppercase;
    color: white;
    letter-spacing: 0.1vh;
}

.login-panel .login-options>li:hover {
    opacity: 1;
}

.login-panel .login-options>li.active {
    opacity: 1;
}

.login-panel .account-form {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.login-panel .account-form-fields {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: all 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.login-panel .account-form-fields>input {
    border: 0;
    margin-bottom: 10px;
    padding: 1.5dvh 2dvh;
    font-size: var(--small-text-size);
    color: white !important;;
    border-radius: 5px;
}

.login-panel .account-form-fields>input::placeholder {
    color: #aaa;
}

.login-panel .account-form-fields>input::-webkit-input-placeholder {
    color: #aaa;
}

.login-panel .account-form-fields>input:-ms-input-placeholder {
    color: #aaa;
}

.login-panel .account-form-fields>input:focus {
    outline: none;
}

.login-panel .btn-submit-form {
    border: 0;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
    padding: 1.5dvh 2dvh;
    border-radius: 5px;
    color: #fff;
    font-size: var(--small-text-size);
    font-family: Nunito, sans-serif;
    background: var(--main-purple);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.13);
    transition: all .2s ease;
}

.login-panel .btn-submit-form:hover {
    background: var(--main-purple);
}

.login-panel .btn-submit-form:active,
.login-panel .btn-submit-form:focus {
    outline: none;
    background: var(--main-purple);
}

.login-panel footer {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 30px 0;
    text-align: center;
}

.login-panel footer>a {
    color: #fff;
    font-weight: 700;
}
