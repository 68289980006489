#GameReborn {
    /*background: linear-gradient(0deg, #472B5C, #70486B);*/
    /*background: var(--bg-gradient);*/
    /*background: rgb(65, 50, 77)*/
    --bottom-section-card-height: min(40vh, 40dvh);
    --card-board-card-width: min(28.6vh, 28.6dvh);
    --card-board-card-height: 40vh;
    --top-bar-height: 7.5vh;
}

#GameReborn img {
    /*image-rendering: crisp-edges;*/
}

#GameReborn * {
    box-sizing: border-box !important;
}

#GameReborn.withBackgroundImage {
    /*background-image: url('./../../assets/Icons/BackgroundTest.jpg') !important;*/
    background-size: cover !important;
    background-position: 50% 25%;
}

#GameReborn.withBackgroundImage::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.24693627450980393) 19%, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 0) 100%);*/
}

#GameReborn .game-main {
    height: 100vh;
    max-height: 100dvh;
    display: flex;
    /*
    transform: perspective(1900px) rotateX(20deg);
    transition: all 1s ease-in-out;*/
}

.side {
    width: 32vh;
    height: 100%;
}

.graveyard {
    width: calc(var(--bottom-section-card-height) / 1.39);
    height: var(--bottom-section-card-height);
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    margin-right: 0 1vh;
    margin-left: 1vh;
}

.library {
    width: calc(var(--bottom-section-card-height) / 1.39);
    height: var(--bottom-section-card-height);
    padding: 1dvh 0.5dvh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
}

.library>p,
.graveyard>p {
    position: absolute;
    top: 9.2dvh;
    left: 50%;
    transform: translate(-50%, -50%);
}

#GameReborn .graveyard-and-library .card.blurred-background.shadowed {
    background-color: rgba(0, 0, 0, 0.185) !important;
}

#GameReborn .library .card-pile {
    background-color: rgb(29, 29, 29);
    background-image: url('./../../assets/CardEffect.PNG');
    height: calc(var(--bottom-section-card-height) - 5vh);
    width: calc((var(--bottom-section-card-height) - 2dvh) / 1.39);
    border-radius: 1.5vh !important;
    border: 0 !important;
    filter: brightness(30%);
    position: absolute;
    bottom: 1dvh;
}

#GameReborn .library p {
    top: 9dvh !important;
}

#GameReborn .library img {
    position: absolute;
}

#GameReborn .library .card {
    margin: 0 !important;
    top: 0;
}

#GameReborn .library .deck-wrapper {
    width: calc((var(--bottom-section-card-height) - 2dvh) / 1.39);
}

.board {
    white-space: nowrap;
    width: 100%;
}

.board .card:hover {
    cursor: pointer;
}

#GameReborn .board>.scroll-container>div {
    align-content: flex-start;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 45vh;
    max-height: 45dvh;
    justify-content: flex-start;
    row-gap: 0;
    margin-top: 10vh;
}

#GameReborn .bottom-part {
    height: var(--bottom-section-card-height);
    --card-board-card-height: calc(var(--bottom-section-card-height) - 2dvh);
    --card-board-card-width: calc((var(--bottom-section-card-height) - 2dvh) / 1.39)
}

#GameReborn .bottom-part .board>.scroll-container>div {
    align-content: flex-start;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: var(--bottom-section-card-height);
    justify-content: flex-start;
    row-gap: 0;
    margin-top: 0;
}

#GameReborn .board>.scroll-container>div>.margin {
    height: 80vh;
    height: 80dvh;
    width: 40vw;
}

#GameReborn .board>p {
    position: relative;
    top: 9.5vh;
    left: 1.5vh;
    height: 0;
    width: 100%;
    margin: 0;
    text-align: left;
    pointer-events: none;
}

#GameReborn .bottom-part .board>p {
    top: -2.2vh;
}

/*--------- CARDS ---------*/

#GameReborn .card-wrapper {
    min-width: var(--card-board-card-width);
    height: var(--card-board-card-height);
    margin: 2.5vh 0.5dvh;
    border-radius: 1.5vh;
    box-shadow: 0 0 0.8vh rgba(0, 0, 0, 0.892);
}

#GameReborn .card-wrapper .card-image.shadowed {
    box-shadow: none;
}

#GameReborn .card-wrapper .default-button {
    z-index: 1;
}

#GameReborn .count-stack-card {
    height: var(--card-board-card-height) !important;
    max-width: 2vh !important;
    width: 2vh;
}

#GameReborn .count-stack,
#GameReborn .cards-stack {
    max-width: 14vh;
}

#GameReborn .count-stack .card-image {
    width: var(--card-board-card-width);
    height: var(--card-board-card-height);
    left: calc(var(--card-board-card-width) * -1 + 100%);
    border-radius: 1.8vh;
    overflow: hidden;
}

#GameReborn .count-stack .card-back-button {
    display: none;
}

#GameReborn .card {
    height: var(--card-board-card-height);
    max-height: 40dvh;
    width: var(--card-board-card-width);
    border: 0 !important;
    background-color: transparent !important;
    border-radius: 1.5vh !important;
    overflow: visible;
    padding: 0;
    border: 0;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
}

#GameReborn .card img, 
#GameReborn .card .foil-effect,
#GameReborn .card .foil-effect-new {
    border-radius: 1.5vh !important;
}
/*
#GameReborn .bottom-part .card {
    height: calc(var(--bottom-section-card-height) - 2dvh);
    width: calc((var(--bottom-section-card-height) - 2dvh) / 1.39);
}
*/

#GameReborn .bottom-part .board .card-wrapper,
#GameReborn .bottom-part .graveyard .card {
    margin: 1dvh 0.5dvh !important;
}

#GameReborn .bottom-part .card,
#GameReborn .bottom-part .card img,
#GameReborn .bottom-part .card .foil-effect,
#GameReborn .bottom-part .card .foil-effect-new {
    height: calc(var(--bottom-section-card-height) - 2dvh);
    width: calc((var(--bottom-section-card-height) - 2dvh) / 1.39);
    border-radius: 1.2vh;
}




.bottom-part .graveyard-and-library .card img,
.bottom-part .graveyard-and-library .card .foil-effect,
.bottom-part .graveyard-and-library .card .foil-effect-new {
    height: calc(var(--bottom-section-card-height) - 2dvh) !important;
    width: calc((var(--bottom-section-card-height) - 2dvh) / 1.39) !important;
    margin: 0 0dvh;
    border-radius: 1.2vh;
}

#GameReborn .card img,
#GameReborn .card .foil-effect,
#GameReborn .card .foil-effect-new {
    height: 40vh;
    width: min(28.6vh, 28.6dvh);
    object-fit: cover;
}

#GameReborn .card img {
    max-height: 40dvh;
}

.cardCount {
    position: absolute;
    top: 10vh;
    left: 50%;
    color: white;
    transform: translate(-50%, -50%);
    font-weight: var(--bold-font-weight);
    font-size: 6vh;
    text-shadow: 1px 1px 15px black;
    pointer-events: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
}

.card h1 {
    color: white;
    font-size: 3vh;
    position: absolute;
    top: 17.5vh;
    right: -1.5vh;
    font-size: 4vh;
    transform: translate(-50%, -50%);
    width: 8vh;
    height: 8vh;
    border-radius: 4vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    pointer-events: none;
}

.card .card-counters {
    
}

#GameReborn .cardsToCast>.scroll-container .cardsToCast-section {
    display: inline-block;
    margin-left: 2.5vw;
    margin-right: 2.5vw;
}

.cardsToCast>.scroll-container>div,
.graveyardView>.scroll-container>div,
.attackersPanel>.scroll-container>div {
    display: inline-block;
    overflow: visible;
    white-space: nowrap;
    margin-left: 28vh;
    margin-right: 28vh;
}
#GameReborn .graveyardView .card,
#GameReborn .cardsToCast .card {
    margin-left: 2vw;
    margin-right: 2vw;
    display: inline-block;
    height: 65vh;
    min-width: 36vh;
    background-color: transparent !important;
    overflow: visible;
}

#GameReborn .card-action-info .action-info {
    background-color: rgba(0, 0, 0, 0.433);
    position: absolute;
    top: 0 !important;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--small-text-size);
    font-weight: var(--bold-font-weight);
    margin: 0;
    border-radius: 1.5vh !important;
    opacity: 0;
    transition: opacity 0.15s;
    pointer-events: none;
}

#GameReborn .card-action-info:hover .action-info {
    opacity: 1;
}

#GameReborn .token-row .action-info {
    border-radius: 0.3vh !important;
}

#GameReborn .graveyard:has(.foil-effect) .card-action-info .action-info,
#GameReborn .library:has(.foil-effect) .card-action-info .action-info,
#GameReborn .card-wrapper:has(.foil-effect) .card-action-info .action-info {
    background-color: transparent !important;
}

#GameReborn .graveyardView .card .action-info {
    height: 50vh;
    height: 50dvh;
}

#GameReborn .card-action-info.revealed-card .action-info {
    margin: 1dvh 0.5dvh !important;
    width: calc(100% - 1vh);
}

#GameReborn .graveyardView .card-image:hover {
    cursor: pointer !important;
}

#GameReborn .card-image,
#GameReborn .cardsToCast .card-image,
#GameReborn .graveyardView .card-image {
    border-radius: 1.8vh !important;
}

#GameReborn .cardsToCast img,
#GameReborn .graveyardView img,
#GameReborn .attackersPanel .card,
#GameReborn .attackersPanel img,
#GameReborn .cardsToCast .foil-effect,
#GameReborn .cardsToCast .foil-effect-new,
#GameReborn .graveyardView .foil-effect,
#GameReborn .graveyardView .foil-effect-new {
    width: 36vh;
    width: 36dvh;
    height: 50vh;
    height: 50dvh;
    max-height: 50dvh;
    object-fit: cover;
    border-radius: 1.8vh !important;
}

.attackersPanel .big-button {
    position: fixed;
    right: 50%;
    bottom: 1vh;
}

#GameReborn .card-token-row {
    flex-shrink: 0;
    height: calc(var(--top-bar-height) - 1vh);
    width: calc((var(--top-bar-height) - 1vh) / 1.39);
    border-radius: 0.3vh;
    overflow: hidden;
    padding: 0;
    border: 0;
    margin: 0.5vh 0.4vh;
    background-color: transparent;
    position: relative;
}

#GameReborn .graveyard .h-100 {
    position: absolute !important;
    left: 0;
    top: 0;
}

#GameReborn .card-token-row .card-image {
    border-radius: 0.3vh !important;
}

#GameReborn .card-token-row .card-image,
#GameReborn .card-token-row .foil-effect,
#GameReborn .card-token-row .foil-effect-new {
    height: calc(var(--top-bar-height) - 1vh);
    width: calc((var(--top-bar-height) - 1vh) / 1.39);
    object-fit: cover;
}

#GameReborn .card-token-row-more {
    position: relative !important;
    display: flex;
    top: 5vh;
    left: 0;
    width: 4vh;
    height: 4vh;
    z-index: 400;
}

.countersMode>p {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.countersMode .default-button {
    color: white;
    width: 5vh;
    height: 5vh;
    min-width: 5vh;
    border-radius: 2.5vh;
    padding: 0;
    font-size: 3vh;
    display: inline-flex;
    justify-content: center;
    text-align: center;
}

.mill-bubble {
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    right: 0;
    top: -5vh;
    z-index: 1;
    border-radius: 1vw;
    overflow: hidden;
    padding: 1vh 0;
}

.mill-bubble h1 {
    font-size: var(--small-text-size);
}

.mill-bubble p {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.5vh;
}

.hand {
    position: fixed;
    width: 80vw;
    margin-left: 10vw;
    height: 25vh;
    overflow: hidden;
    white-space: nowrap;
    top: -11vh;
    filter: drop-shadow(0 2vh 1.2vh rgba(0, 0, 0, 0.421));
}

.card-hand {
    height: 24vh;
    width: 17.28vh;
    object-fit: cover;
    border-radius: 1vh;
    margin-left: 0.4vw;
    margin-right: 0.4vw;
    transform: rotate(180deg);
    cursor: pointer;
}

.help-buttons {
    min-width: 30vh;
    margin-right: 3vh;
    margin-top: 2.5vh;
}

.help-buttons button {
    background: none;
    border: 0;
    font-size: 4vh;
    font-weight: var(--semi-bold-font-weight);
    text-align: start;
    opacity: 0.7;
    margin-left: 0.5vh;
}

.help-buttons button.selected {
    opacity: 1;
}

.help-buttons button:hover {
    opacity: 1;
}

.help {
    overflow-x: hidden;
    overflow-y: scroll;
    white-space: nowrap;
    white-space: normal;
    font-size: 2vh;
    text-align: start;
    padding: 1vh;
    padding-right: 3vh;
    width: 100%;
    height: 90vh;
}

.help img {
    width: 80%;
    margin: 5vh 10%;
}

.fullscreen-blurred-background:has(#HelpPanel) {
    background: var(--light-gray-background);
}

.help h1 {
    margin-top: 2vh;
    margin-bottom: 4vh;
}

.help h2 {
    margin-bottom: 0;
    margin-top: 2vh;
    font-size: 3vh;
}

.playHand-buton {
    position: fixed;
    top: 0vh;
    left: 0vh;
}

.library-topCard-manager {
    position: relative;
    top: calc(var(--bottom-section-card-height) / -3);
    width: 12vh;
    margin-top: 10vh;
    left: -4vh;
    justify-content: center;
}

.library-topCard-manager button {
    padding: 0.5vh 1.5vh;
    margin: 0.5vh;
}

.library-topCard-manager-reveal {
    position: relative;
    width: 100%;
    top: calc((var(--bottom-section-card-height) / 3) + 2.5vh);
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
}


.alternate-draw-buttons button {
    min-width: 4.55dvh !important;
}

.graveyard .tokenBeingExiled {
    animation: tokenExiled 1s normal;
    position: absolute !important;
    left: 0;
    top: 0;
    opacity: 0;
    pointer-events: none;
}

.graveyard .tokenExiled-wrapper {
    pointer-events: none;
}

@keyframes tokenExiled {
    0% {
        opacity: 1;
        transform: scale(1) rotate(0deg) translateX(0px) rotate(-0deg);
    }

    /*
    20% {
        opacity: 1;
        transform: scale(1);
    }*/

    100% {
        opacity: 0;
        transform: scale(0.5) rotate(-45deg) translateX(70vh) rotate(45deg);
    }
}

@media (max-width: 992px) {
    /*
    .board>.scroll-container>div {
        flex-direction: row !important;
    }

    .board>.scroll-container>div .card {
        max-height: 56dvh !important;
        max-width: 40.4dvh !important;
        border-radius: 3vh !important;
        transform: scale(1.7);
    }*/
}


.graveyard .type-count {
    position: absolute;
    top: 0;
    left: 0;
    padding: 1.5vh 1.2vh;
}

.graveyard .type-count div {
    margin-bottom: 0.5vh;
    padding: 0.5vh 1.5vh;
    border-radius: 10vh;
}

.graveyard .type-count img {
    width: 3vh;
    height: 3vh;
    text-shadow: 1px 1px 15px black;
}

.graveyard .type-count h2 {
    font-size: 2.5vh;
    font-weight: var(--bold-font-weight);
    color: white;
    margin: 0;
    margin-left: 1vh;
    text-shadow: 1px 1px 15px black;
}

.graveyard .type-count>div p {
    overflow: visible !important;
    position: fixed;
    top: -5vh;
    left: 0vh;
    width: fit-content;
}

.graveyard .type-count>div:nth-child(2) p {
    top: -9.5vh;
}

/*--------- SIDE BAR --------*/

.side-bar {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 3;
    height: var(--top-bar-height);
    width: 100%;
    backdrop-filter: blur(2vh);
    background-color: rgba(0, 0, 0, 0.308);
}

.withBackgroundImage .side-bar {
    background-color: transparent !important;
}

.side-bar::-webkit-scrollbar {
    display: none;
}

.side-bar>.interaction-row>button,
.side-bar>button,
.countersMode>button,
.board-wipe-confirm>button  {
    background: transparent;
    border: 0;
    color: white;
    font-size: var(--small-text-size);
    font-weight: var(--regular-font-weight);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 1.1vh;
    padding-right: 1.1vh;
    width: 6vh;
    height: var(--top-bar-height);
    border-bottom: 0.5vh solid transparent;
    padding-top: 0.5vh;
    transition: all 0.3s ease-in-out;
}

.side-bar button:hover img {
    opacity: 0.7;
}

.side-bar button:hover .zoom-background img {
    opacity: 1;
}

.side-bar>.interaction-row>button p,
.side-bar>button p,
.draw-per-turn-indicators p,
.countersMode>button p,
.type-count p {
    position: relative;
    top: 7vh;
    right: 4.5vh;
    opacity: 0;
    white-space: nowrap;
    text-wrap: nowrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
    transition: opacity 0.15s ease-in-out;
    padding: 0.5vh 1vh;
    border-radius: var(--Main-UI-border-radius);
    margin: 0;
    background-color: rgb(0, 0, 0) !important;
    pointer-events: none;
}

.draw-per-turn-indicators:hover p,
.side-bar>.interaction-row>button:hover p,
.side-bar>button:hover p,
.countersMode>button:hover p,
.type-count>div:hover p  {
    opacity: 1;
}

.side-bar>button>img,
.side-bar>.interaction-row>button>img,
.countersMode>button>img,
.board-wipe-confirm>button>img {
    width: 5vh;
    height: 3.8vh;
}

.side-bar button.enabled {
    opacity: 1 !important;
    border-bottom: 0.5vh solid white;
}

.side-bar button.enabled img {
    opacity: 0.7;
}

.side-bar .divider {
    height: 4vh;
    width: 4vh;
}

.side-bar .token-row {
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.side-bar .token-row::-webkit-scrollbar {
    display: none;
}

.turn-buttons {
    margin-left: auto;
}

.turn-buttons>p {
    width: 12vh;
}

.board-row-title {
    font-weight: var(--semi-bold-font-weight);
    text-shadow: 1px 1px 15px black;
}

/*------------------- Attackers panel -------------------*/

.attackersPanel {
    z-index: 4;
}

.attackersPanel button,
#GameReborn .side-bar .default-button {
    color: white;
    /*background-color: rgba(44, 44, 44, 0.308) !important;*/
    background-color: rgba(22, 22, 22, 0.767) !important;
    font-weight: var(--semi-bold-font-weight) !important;
    font-size: var(--small-text-size) !important;
    border-radius: 10vh !important;
    padding: 0.8vh 2.5vh;
    min-width: 18vh;
    height: 5vh;
}

.attackersPanel button {
    border: 0;
    margin-top: 2vh;
    min-width: 14vh;
}
  
.attackersPanel button.primary,
#GameReborn .default-button.primary {
    background-color: white !important;
    color: black;
}

#GameReborn .default-button {
    background-color: var(--black-transparent-background) !important;
    backdrop-filter: blur(10px) !important;
}

/*------------------- Draw Indicators -------------------*/

.draw-per-turn-indicator {
    height: 0.5vh;
    width: 6vh;
    background-color: white;
    border-radius: 1vh;
    margin-left: 0.5vh;
    margin-right: 0.5vh;
    cursor: pointer;
}

.draw-per-turn-indicator:hover {
    opacity: 0.7;
}

.draw-per-turn-indicator.nope {
    background-color: rgba(184, 184, 184, 0.644);
}

.draw-per-turn-indicators {
    height: 0.5vh;
    width: 18vh;
    margin-left: 1vh;
    position: absolute;
    top: 0.2vh;
}

.draw-per-turn-indicators p {
    top: 8.4vh;
    right: 0;
    width: 21vh;
}

.draw-button {
    margin-bottom: 1.5vh;
}



.deck-special-rules {
    width: 3vh;
    height: 3vh;
    font-size: var(--small-text-size);
    color: rgb(255, 255, 255);
    text-align: center;
    position: fixed;
    top: 8.5vh;
    right: 1vh;
    border: 2px solid white;
    border-radius: 10vh;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.deck-special-rules:hover div {
    display: block;
}

.deck-special-rules div {
    background-color: black;
    position: absolute;
    top: -0.5vh;
    left: calc(-40vw + 3vh);
    display: none;
    width: 40vw;
    padding: 1vh;
    border-radius: 0.5vh;
    z-index: 1000;
    max-height: 70vh;
    overflow-y: scroll;
    text-align: start;
    white-space: pre-line;
}

.deck-special-rules h2 {
    font-size: var(--small-text-size);
}

.card-quick-button {
    position: absolute;
    top: -2vh;
    transition: all ease-in-out 0.15s;
}

.card-quick-button button {
    opacity: 0;
}

.card-quick-button:hover button {
    opacity: 1;
}

.draw-card-count {
    opacity: 0.7;
    margin-left: 1.5vh;
    margin-top: 0.5vh;
}

/* Multiplayer */

.interaction-disabled {
    pointer-events: none;
}

.hidden,
.card-back-button.hidden,
.interaction-row.hidden {
    display: none !important;
}

#GameReborn .player-count {
    position: fixed;
    top: 8.5vh;
    right: 5vh;
    padding: 0.2vh 1vh;
    border-radius: var(--Main-UI-border-radius);
    color: white;
}

.board-sections-and-background .background {
    background-size: cover;
}


/*
.game-main {
    perspective: 1000px;
}

.board-sections-and-background .background {
    scale: 1.1;
}

.with-3d {
    transform: rotateX(10deg);
}*/

.spectating .action-info {
    display: none !important;
}

.spectating .pop-up-menu {
    display: none !important;
}