.newNavBar {
    background-color: rgba(0, 0, 0, 0);
    position: absolute !important;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 50;
    /*border-bottom: 1px solid rgba(255, 255, 255, 0.5);*/
    background-color: rgba(0, 0, 0, 0.144);
    backdrop-filter: blur(10px);
}

.newNavBar img {
    margin-right: 0.5rem;
}

.newNavBar a {
    font-size: var(--small-text-size);
    font-weight: 400;
    color: #fff !important;
    margin-right: 1.5rem;
    text-decoration: none;
    padding-top: 1vh;
    padding-bottom: 1vh;
}

.newNavBar a:hover {
    text-decoration: underline;
}

.newNavBar .disabled {
    cursor: default;
    opacity: .3;
    pointer-events: none;
    text-decoration: none;
}

.newNavBar .navbar-brand {
    font-weight: var(--semi-bold-font-weight);
}

.newNavBar .navbar-brand img {
    margin-right: 1rem;
}

.newNavBar .default-button {
    background-color: white !important;
    height: 4vh;
    border-radius: 2vh;
    color: black;
    padding-left: 2vh;
    padding-right: 2vh;
    margin: 0;
}

.newNavBar .navbar-toggle {
    color: #fff !important;
}

.logout-button {
    height: 4vh;
    margin-left: 1vh;
}

.logout-button:hover {
    opacity: 0.7;
}

.logo{
    width: 4vh;
    height: 4vh
}

.icon {
    width: 3vh;
    height: 3vh;
    margin-bottom: 0.2vh;
}

.link.selected {
    font-weight: var(--bold-font-weight);
}


@media only screen and (orientation:portrait) {
    .newNavBar {
        background-color: var(--gray-background);
        border-bottom: 1px solid transparent;
    }

    .newNavBar .default-button {
        margin-bottom: 2vh;
    }
}

@media screen and (max-width: 1200px) {
    .newNavBar {
        background-color: var(--gray-background);
        border-bottom: 1px solid transparent;
    }

    .newNavBar .default-button {
        margin-bottom: 2vh;
    }

    .newNavBar .login-username-logout {
        margin-bottom: 1vh;
    }
}