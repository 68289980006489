.fadeIn {
  animation: fadeInAnimation 0.3s linear;
}

body {
  background: var(--light-gray-background) !important;
}

:root {
  --main-purple: #4a346d;
  --lighter-main-purple: #835ac4;
  --regular-font-weight: 300;
  --semi-bold-font-weight: 600;
  --bold-font-weight: 700;
  --Main-UI-border-radius: 0.5vh;
  --UI-elements-border-radius: 1.5vh;
  --bg-gradient: linear-gradient(0deg, #3c3342, #584961);
  --bg-gradient-1: linear-gradient(0deg, #3c3342, #584961);
  --bg-gradient-2: linear-gradient(0deg, #323035, #323035);
  --bg-gradient-3: linear-gradient(0deg, #454F45, #525C57);
  --bg-gradient-4: linear-gradient(0deg, #794b64, #9e4e61);
  --bg-gradient-5: linear-gradient(0deg, #502828, #803a37);
  --bg-gradient-6: linear-gradient(0deg, #1c4564, #3b5770);
  --bg-gradient-7: linear-gradient(0deg, #1b2538, #2c4055);
  /*
    --bg-gradient-1: linear-gradient(0deg, #3c3342, #584961);
  --bg-gradient-2: linear-gradient(0deg, #323035, #323035);
  --bg-gradient-3: linear-gradient(0deg, #454F45, #525C57);
  --bg-gradient-4: linear-gradient(0deg, #75425E, #B04F66);
  --bg-gradient-5: linear-gradient(0deg, #632B2B, #8F3633);
  --bg-gradient-6: linear-gradient(0deg, #1A4769, #3D6180);
  --bg-gradient-7: linear-gradient(0deg, #121C2E, #243B54);
  */
  /*--light-gray-background: #3a373d;
  --gray-background: #1e1d20;
  */
  --light-gray-background: #28262d;
  --gray-background: #161318;
  --vignette-purple-1: rgba(41, 33, 43, 0.5);
  --vignette-purple-2: rgba(72, 49, 87, 0.2);
  --small-text-size: max(12px, 1.8vh);
  --large-text-size: max(18px, 2.5vh);
  --extra-large-text-size: 5vh;
  --deck-picker-height: 28vh;
  --black-transparent-background: rgba(0, 0, 0, 0.8);
  /* SUPPORTED BY EVERYONE? */
}

@font-face {
  font-family: "roboto";
  src: url("./Roboto-Regular.ttf");
}

@font-face {
  font-family: "roboto";
  src: url("./Roboto-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "roboto";
  src: url("./Roboto-Light.ttf");
  font-weight: 300;
}

body {
  font-family: "roboto" !important;
  font-weight: var(--regular-font-weight);
}

button {
  font-family: "roboto";
}

*,
::after,
::before {
  box-sizing: content-box !important;
}

h1,
h2 {
  color: white;
  font-weight: var(--semi-bold-font-weight) !important;
}

p,
label {
  color: white;
  font-weight: var(--regular-font-weight);
  font-size: var(--small-text-size);
  overflow-wrap: anywhere;
}

body p {
  margin-top: 1em;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.default-button:hover,
.big-button:hover {
  filter: brightness(80%);
  transition: all;
  transition-duration: 0.3s;
}

button {
  color: white;
  font-size: var(--large-text-size);
  font-weight: var(--regular-font-weight);
}

.enabled {
  opacity: 0.5 !important;
}

.big-button {
  color: black;
  padding: 2vh;
  min-width: 14vw;
  font-size: var(--large-text-size);
  font-weight: 600;
  transition-property: opacity;
  transition-duration: 0.3s;
  letter-spacing: 0.1vh;
  height: 8vh;
  margin: 1vh;
  border: 0;
  padding: 0 1.5vh;
  border-radius: 20vh;
  transition-property: opacity;
  transition-duration: 0.3s;
  background-color: white !important;
}

button.purpleOutline {
  border: 1px solid white !important;
}

.fullscreen-blurred-background {
  height: 100vh;
  height: 100dvh;
  width: 100vw;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  background-color: rgba(30, 30, 30, 0.459);
  backdrop-filter: blur(5vh);
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
}

.blurred-background {
  /*background-color: rgba(30, 30, 30, 0.5) !important;*/
  /*background-color: rgba(10, 10, 10, 0.5) !important;*/
  background-color: var(--black-transparent-background) !important;
  backdrop-filter: blur(10px) !important;
}

.shadowed {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 0.2vw 0.6vw;
}

#Game .default-button {
  color: white;
  margin: 1vh;
  border: 0;
  padding: 0 1.5vh;
  min-width: 6vh;
  border-radius: var(--UI-elements-border-radius);
  font-size: var(--small-text-size);
  font-weight: 400;
  transition-property: opacity;
  transition-duration: 0.3s;
  height: 7vh;
}

.default-button {
  color: white;
  margin: 1vh;
  border: 0;
  padding: 0 1.5vh;
  font-size: var(--small-text-size);
  font-weight: 400;
  transition-property: opacity;
  transition-duration: 0.3s;
  background-color: rgba(73, 73, 73, 0.363) !important;
  border-radius: 10vh;
  height: 5vh;
  min-width: 11vh;
}

.default-button.primary {
  background-color: white !important;
  color: black;
}

.default-button.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: default;
  text-decoration: none;
}

.selectable-element {
  color: gray;
  font-size: calc(var(--small-text-size) + 0.5vh);
  background-color: transparent;
  border: 0;
  transition: all;
  transition-duration: 0.3s;
  font-weight: 600;
  padding: 0;
}

.selectable-element.selected {
  color: white;
}

input[type="checkbox"],
input[type="radio"] {
  background-color: #6a4e66;
  vertical-align: middle;
  width: max(2.5vh, 15px);
  height: max(2.5vh, 15px);
  -webkit-appearance: none;
  border-radius: 0.5vh;
  margin: 0;
  margin-right: 1vh;
  margin-bottom: 0.5vh;
  box-shadow: rgba(0, 0, 0, 0) 0px 0.2vw 0.6vw;
  transition: all;
  transition-duration: 0.3s;
  cursor: pointer;
}

input[type="checkbox"]:checked,
input[type="radio"]:checked {
  background-color: var(--main-purple);
  width: max(2.5vh, 15px);
  height: max(2.5vh, 15px);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 0.2vw 0.6vw;
}

input[type=checkbox]::before,
input[type="radio"]::before {
  content: "";
  color: transparent;
  display: block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  border: 0;
  background-color: transparent;
  background-size: contain;
}

input[type=checkbox]:checked::before,
input[type="radio"]:checked::before {
  box-shadow: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E %3Cpath d='M15.88 8.29L10 14.17l-1.88-1.88a.996.996 0 1 0-1.41 1.41l2.59 2.59c.39.39 1.02.39 1.41 0L17.3 9.7a.996.996 0 0 0 0-1.41c-.39-.39-1.03-.39-1.42 0z' fill='%23fff'/%3E %3C/svg%3E");
}

.unusable {
  opacity: 0.3;
  pointer-events: none;
}

textarea {
  resize: none !important;
  color: white;
  border: 1vh solid transparent;
  border-radius: var(--Main-UI-border-radius);
  font-size: var(--small-text-size) !important;
}

::-webkit-scrollbar {
  width: 0.8vh;
}

::-webkit-scrollbar-track {
  border-radius: 0.4vh;
}

::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 0.4vh;
}

/*--------------------- main-navbar ---------------------*/

.navbar-toggler-button {
  position: fixed;
  left: 0vh;
  top: 10vh;
  z-index: 10;
  border: 1px solid gray;
  border-radius: 0 !important;
  color: white !important;
}

.navbar-toggler-icon {
  color: white !important;
}

.navbar-toggler-button {
  top: 0 !important;
}

@media only screen and (orientation:portrait) {
  .navbar .main-navbar {
    width: 100vw !important;
    margin-top: 0dvh;
    max-height: 100vh !important;
  }

  .main-navbar .logo {
    margin-top: 4dvh;
    margin-bottom: 6dvh;
    display: flex !important;
  }

  .image {
    display: none !important;
  }

  .options {
    width: calc(100vw - 8vh) !important;
  }

  /*
  .main {
    padding-top: 4dvh !important;
  }
*/
  .main-navbar {
    padding-top: 4dvh !important;
    height: 96dvh !important;
  }

  .zoom-background {
    display: none;
  }
}

.navbar {
  padding: 0 0 !important;
}

.main-navbar {
  width: 30vh;
  height: 100vh;
  height: 100dvh;
  opacity: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
  overflow: hidden;
}

.main-navbar .main-links {
  margin-top: 11dvh;
  width: 100%;
}

.main-navbar .logo,
.main-navbar .link {
  display: flex;
  padding-left: 1.5vh;
  width: 28.5vh;
}

.main-navbar .link {
  opacity: 0.8;
  border-left: 5px solid transparent;
  cursor: pointer;
}

.main-navbar .link.selected {
  border-left: 5px solid white;
  opacity: 1;
}

.main-navbar .link.disabled {
  opacity: 0.3;
  pointer-events: none;
  cursor: default;
  text-decoration: none;
}

.main-navbar p.link,
.main-navbar a.link {
  font-weight: 500;
  font-size: calc(var(--small-text-size) + 0.1dvh);
  color: white;
  outline: none;
  text-decoration: none;
  display: block;
  margin-block-start: 1.2vh;
  margin-block-end: 1.2vh;
  margin-inline-start: 0;
  margin-inline-end: 0;
  margin-left: 0;
  font-weight: var(--semi-bold-font-weight);
}

.main-navbar h1 {
  font-weight: var(--bold-font-weight);
  font-size: calc(var(--small-text-size) + 0.25dvh);
  position: relative;
  top: 2.2vh;
}

.main-navbar h4 {
  font-size: calc(var(--small-text-size) - 0.5vh);
  margin-left: 1vh;
  font-weight: var(--semi-bold-font-weight);
}

.main-navbar .logo img {
  width: 4.5vh;
  height: 4.5vh;
  border-radius: 0.5vh;
  position: relative;
  top: 1.6vh;
  margin-right: 1vh;
}

.main-navbar .icon {
  width: 3vh;
  height: 3vh;
  margin-right: 1.5vh;
  position: relative;
  top: -0.2vh;
}

.main-navbar .link:hover {
  color: var(--main-purple);
}

.main-navbar .link:hover img {
  filter: invert(18%) sepia(51%) saturate(3405%) hue-rotate(253deg) brightness(94%) contrast(84%);
}

.main-navbar .link-patreon:hover {
  color: #FF424D;
}

.main-navbar .link-patreon:hover img {
  filter: invert(49%) sepia(66%) saturate(5782%) hue-rotate(334deg) brightness(106%) contrast(105%);
}

.main-navbar .login {
  width: calc(100% - 6vh);
  margin: 1vh;
  margin-bottom: 2vh;
  padding: 2vh;
  border-radius: var(--Main-UI-border-radius);
  opacity: 1;
}

.main-navbar .login>p {
  font-size: calc(var(--small-text-size) - 0.3vh);
  margin: 0;
}

.main-navbar .login-username {
  height: 6vh;
  height: 6dvh;
}

.main-navbar .login-username p {
  margin-top: 0 !important;
}

.main-navbar .login .default-button {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0;
  float: right;
}

.main-navbar .login h2 {
  font-size: 3.5vh !important;
  font-weight: var(--semi-bold-font-weight);
}

.login-return-button {
  position: fixed;
  top: 1vh;
  left: 1vh;
  z-index: 2000;
}




.invisible {
  opacity: 0;
}

.navbar.invisible {
  position: fixed;
}

.foreground {
  display: flex;
  position: absolute;
}

.fullscreen {
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  overflow: hidden;
}

.background {
  float: left;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  /*position: absolute;*/
}

/* Safari fix */
.background,
.fullscreen,
.foreground,
.card-panel,
.search-panel,
.deckpicker-bar {
  max-height: 100vh;
}

.side-panel {
  max-height: calc(100vh - var(--topBar-BackgroundHeight));
}

.image {
  max-height: 96vh;
}

.options {
  max-height: calc(96vh - 4dvh);
}

#Game .game-main,
#Game .board,
#Game .side,
.reprint-picker {
  max-height: 90vh;
}

#Game .graveyard,
#Game .library {
  max-height: 45vh;
}

#Game .card img,
#Game .card-pile {
  max-height: 40vh !important;
  max-width: 28.8vh;
}

#Game .card {
  max-height: min(40vh, 40dvh) !important;
  max-width: 28.8vh;
}

.cardsToCast img,
.graveyardView img {
  max-height: 50vh !important;
  max-width: 36vh !important;
}

.cardsToCast .card,
.graveyardView .card {
  max-height: 70vh !important;
  max-width: 36vh !important;
}

.topBar {
  /*max-height: 10vh;*/
}

.card-editor-panel,
.card-editor-panel img {
  max-height: 45vh;
  max-width: 32.6vh;
}

.reprint-picker>div {
  max-height: 80vh;
}

/* End Safari fix */

.unselected {
  opacity: 0.5;
}

.background>img {
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  object-fit: cover;
  object-position: 50% top;
  filter: brightness(30%);
}

.vignette {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 0 rgba(0, 0, 0, 1) inset;
  /*background: linear-gradient(0deg, rgba(71, 43, 92, 0.8) 0%, rgba(71, 43, 92, 0.8) 20%, rgba(71, 43, 92, 0.24693627450980393) 35%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%);*/
  /*background: linear-gradient(0deg, var(--vignette-purple-1) 0%, var(--vignette-purple-1) 20%, var(--vignette-purple-2) 35%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%);*/
  backdrop-filter: blur(4px);
}

.nohting {
  background: linear-gradient(0deg, rgba(71, 43, 92, 0.8) 0%, rgba(71, 43, 92, 0.8) 20%, rgba(71, 43, 92, 0.24693627450980393) 39%, rgba(0, 0, 0, 0.2) 50%, rgba(0, 0, 0, 0.2) 100%);
}

/*---------------- THANKS ----------------*/

.thanks-panel img {
  margin-top: 3vh;
}

.thanks-panel span {
  display: flex;
  justify-content: space-around;
  margin-bottom: 4vh;
}

.thanks-panel span div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/*---------------- LANDSCAPE ONLY ----------------*/

.portrait-message {
  position: fixed;
  top: 30%;
  left: 0;
  height: 100%;
  width: 100%;
  padding: 3vh;
}

.portrait-message p {
  font-weight: var(--semi-bold-font-weight);
  font-size: var(--large-text-size);
}

@media only screen and (orientation:portrait) {
  .landscape-only {
    display: none !important;
  }

  div.landscape-only {
    display: none !important;
  }

  .portrait-message {
    display: block;
  }
}

@media only screen and (orientation:landscape) {
  .portrait-message {
    display: none;
  }
}

/*---------------- PATREON ----------------*/

.patreon {
  border-radius: var(--Main-UI-border-radius);
  padding: 0.5vh 1.5vh;
  width: fit-content;
  --uncommon: rgb(185, 220, 235);
  --alt-uncommon: rgb(70, 100, 110);
  --rare: rgb(230, 205, 140);
  --alt-rare: rgb(118, 98, 55);
  --mythic: rgb(245, 145, 5);
  --alt-mythic: rgb(180, 50, 25);
  --shifted: rgb(195, 155, 200);
  --alt-shifted: rgb(100, 40, 120);
}

.patreon-0 {
  padding-left: 0 !important;
  font-weight: var(--semi-bold-font-weight);
  text-shadow: 1px 1px 15px black;
}

.patreon-1 {
  background: linear-gradient(45deg, var(--alt-uncommon), var(--uncommon), var(--alt-uncommon));
  color: black;
  font-weight: var(--semi-bold-font-weight);
}

.patreon-2 {
  background: linear-gradient(45deg, var(--alt-rare), var(--rare), var(--alt-rare));
  color: black;
  font-weight: var(--semi-bold-font-weight);
}

.patreon-3 {
  background: linear-gradient(45deg, var(--alt-mythic), var(--mythic), var(--alt-mythic));
  color: black;
  font-weight: var(--semi-bold-font-weight);
}

.patreon-4 {
  background: linear-gradient(45deg, var(--alt-shifted), var(--shifted), var(--alt-shifted));
  color: black;
  font-weight: var(--semi-bold-font-weight);
}

#Game .sideways-scroll {
  height: 100%;
}

.sideways-scroll {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: relative;
}

.sideways-scroll>div:last-child {
  position: relative;
  top: 0;
  left: 0;
}

.mouseWheelOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.sideways-scroll::-webkit-scrollbar {
  display: none;
}

.cantAttackOrBlock:hover {
  opacity: 0;
}

.cantAttackOrBlock {
  position: absolute;
  font-size: 2vh !important;
  padding: 0 1vh;
  border-radius: 1vh;
  top: 4vh !important;
  white-space: nowrap;
  text-shadow: none !important;
  font-size: var(--small-text-size);
  font-weight: var(--semi-bold-font-weight);
  color: white;
  opacity: 1;
  transition: opacity 0.3s;
  margin: 0;
  background-color: var(--black-transparent-background);
}

.background-gradient-1 {
  background: var(--bg-gradient-1) !important;
}

.background-gradient-2 {
  background: var(--bg-gradient-2) !important;
}

.background-gradient-3 {
  background: var(--bg-gradient-3) !important;
}

.background-gradient-4 {
  background: var(--bg-gradient-4) !important;
}

.background-gradient-5 {
  background: var(--bg-gradient-5) !important;
}

.background-gradient-6 {
  background: var(--bg-gradient-6) !important;
}

.background-gradient-7 {
  background: var(--bg-gradient-7) !important;
}

.background-choice {
  width: 6dvh;
  height: 6dvh;
  display: block;
  border-radius: 0.5vh;
  border: 0.3vh solid rgb(19, 19, 19);
}

.selected>.background-choice {
  border: 0.3vh solid white;
}

.form-control {
  border: 1px solid black !important;
}

input,
select,
textarea {
  color: white !important;
}

#DeckEditor .zoom-background img.zoomingIn,
#GameReborn .zoom-background img.zoomingIn {
  width: 56.9dvh !important;
  height: 80dvh !important;
  max-width: 56.9dvh !important;
  max-height: 80dvh !important;
  border-radius: 2.7dvh !important;
  position: fixed;
  top: 10dvh;
  left: calc(50% - 28.45dvh);
  transform: rotate(0deg);
  transition: transform 0.1s;
  pointer-events: none;
}

#DeckEditor .zoom-background .zoom-rotate-toggle:hover + img.zoomingIn,
#GameReborn .zoom-background .zoom-rotate-toggle:hover + img.zoomingIn {
  transform: rotate(90deg);
}

#DeckEditor .zoom-background .zoom-rotate-toggle,
#GameReborn .zoom-background .zoom-rotate-toggle {
  max-width: 10vh !important;
  max-height: 10vh !important;
  position: fixed;
  top: 10vh;
  right: 10vh;
}

.zoom-background {
  position: fixed;
  z-index: 100000000 !important;
  width: 100vw;
  height: 100dvh;
  left: 0;
  top: 0;
  background-color: var(--light-gray-background);
}
/*
#DeckEditor .zoom-background img.zoomingIn,
#GameReborn .zoom-background img.zoomingIn {
  width: 56.9dvh !important;
  height: 80dvh !important;
  max-width: 56.9dvh !important;
  max-height: 80dvh !important;
  border-radius: 2.7dvh !important;
  position: fixed;
  top: 10dvh;
  left: calc(50% - 28.45dvh);
}

.zoom-background {
  position: fixed;
  z-index: 100000000 !important;
  width: 100vw;
  height: 100dvh;
  left: 0;
  top: 0;
}
*/
.iphone-prevent-zoom>img {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.iphone-prevent-zoom {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

#DeckBrowser h2,
#DeckEditor h2,
#DeckPicker h2 {
  font-size: 2.2vh;
  font-weight: var(--semi-bold-font-weight);
  text-transform: capitalize;
  color: white;
  letter-spacing: 0.1vh;
}

/*---- SEARCH FIELD ----*/

.search-row {
  border-radius: 10vh;
  height: 5vh;
  overflow: hidden;
}

.search-row>form {
  display: flex;
  width: 100%;
}

.search-row .form-control {
  border: 0 !important;
  color: white;
  font-size: 2vh;
  padding: 0.37vh;
  background-color: transparent !important;
  margin-right: 0;
  padding-left: 2vh;
  border-right: 0 !important;
  box-shadow: none;
  font-size: var(--small-text-size);
  border-top-left-radius: var(--UI-elements-border-radius);
  border-bottom-left-radius: var(--UI-elements-border-radius);
  line-height: 0%;
}

.search-row .form-control:focus {
  border-color: inherit !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.search-row input[type="submit"] {
  background-color: transparent;
  border: 0;
  background-image: url('../../assets/Icons/loupe.png');
  background-repeat: no-repeat;
  background-size: 60%;
  background-position: 50% 50%;
  width: 8vh;
  height: 100%;
}

body:has(.noScrollbar)::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body:has(.noScrollbar) {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  overflow-y: hidden;
  overflow-x: hidden;
}

@supports (-moz-appearance:none) {
  body:has(.noScrollbar) {
    overflow-y: hidden;
  }
}

/*-------- MDFC --------*/



.card-back-button {
  position: absolute;
  top: 0;
  left: 0;
}

.card-back-button .default-button {
  position: relative;
  background-color: var(--black-transparent-background) !important;
  width: 8vh;
  min-width: 0 !important;
  height: fit-content;
  transform: translateZ(50vh);
  margin: 0;
  padding: 0.5vh 0;
  border-radius: 0
}

.card-back-button .default-button:first-child {
  border-top-left-radius: 1.5vh;
}

.card-back-button .default-button:last-child {
  border-bottom-right-radius: var(--UI-elements-border-radius);
}

.card-back-button img {
  width: 3vh !important;
  height: 3vh !important;
}

.card-back {
  position: absolute;
  left: 0;
  top: 0;
  transform: rotateY(-90deg);
  transition: transform 0.3s;
  transition-delay: 0s;
  background-color: transparent;
}

.card-front {
  position: absolute;
  left: 0;
  top: 0;
  transform: rotateY(0deg);
  transition: transform 0.3s;
  transition-delay: 0.3s;
  background-color: transparent;
}

.show-card-back .card-back {
  transform: rotateY(0deg);
  transition-delay: 0.3s;
}

.show-card-back .card-front {
  transform: rotateY(90deg);
  transition-delay: 0s;
}

.no-mdfc .card-back-button {
  display: none;
}

/*
@keyframes tokenExiled {
  0% {
    transform: rotateY(0deg);
  }


  100% {
      opacity: 0;
      transform: scale(0.5) rotate(-45deg) translateX(70vh) rotate(45deg);
  }
}*/

.error-message {
  color: rgb(243, 106, 106);
  white-space: pre-wrap;
  font-weight: var(--semi-bold-font-weight);
  text-align: start;
}

/*-------------- FOOTER --------------*/

.footer {
  background: #1c181f;
}

.footer p {
  font-size: 1.8vh;
  margin: 0.5vh;
  text-align: start;
}

/*------------ WITH HELP ------------*/

.App .with-help {
    position: relative;
}

.App .with-help h1 {
    width: 5vh;
    font-size: var(--small-text-size);
    color: rgba(255, 255, 255, 0.616);
    text-align: center;
}

.App .with-help h1:hover p{
    display: block;
}

.App .with-help h1 p {
    background-color: black;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    display: none;
    width: calc(100% - 3vh);
    padding: 1vh;
    border-radius: 0.5vh;
    z-index: 1000;
    pointer-events: none;
    text-align: start;
    white-space: pre-line;
}

.click-with-feedback {
  opacity: 1;
  transform: scale(1);
  transition: all ease-in-out 0.3s;
}

.click-with-feedback.clicked {
  opacity: 0.2;
  transform: scale(0.95);
}

.cursor-pointer {
  cursor: pointer !important;
}

hr {
  border: none;
  /*height: 0.4vh;
  background: linear-gradient(90deg, transparent 10%, white 30%, white 70%, transparent 90%);
}*/}