#HomePage {
    /*background: rgb(20, 20, 20);*/
    /*background-color: var(--gray-background);*/
    background-color: var(--light-gray-background);
}

#HomePage * {
    box-sizing: border-box !important;
}

img.main-section {
    position: fixed;
    top: 0;
    left: 0;
    object-fit: none;
    object-position: 50% 0%;
}


/*
@media (min-width: 576px)
.test {
    min-height: 600px;
    max-height: 648px;
}

@media (min-width: 384px)
.test {
    min-height: 548px;
    max-height: 580px;
}*/

.main-section {
    position: relative;
    height: calc(100vh - 93px);
    min-height: 500px;
    max-height: 780px;
    color: #fff;
    text-align: center;
}

.main-section.user-logged-in {
    max-height: 6vh;
    min-height: 6vh;
}
/*
.main-section:after {
    position: absolute;
    bottom: 0;  
    height: 100%;
    width: 100%;
    content: "";
    background: linear-gradient(to top,
        var(--gray-background) 0%, 
       rgba(255,255,255, 0) 5%
    );
    pointer-events: none;
  }
*/
.main-section .background-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 0;
    object-fit: cover;
    object-position: 50% 25%;
}

.main-section .background-wrapper::after {
    content: '';
    position: absolute;
    left: 0; top: 0;
    width: 100%; height: 100%;
    /*background: linear-gradient(rgba(0, 0, 0, 0.0) 0%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.8) 100%);*/
    background: linear-gradient(rgba(0, 0, 0, 0.0) 0%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.8) 100%);
}

@media only screen and (orientation:portrait) {
    .main-section .background-wrapper::after {
        background: linear-gradient(rgba(0, 0, 0, 0.0) 0%, rgba(0, 0, 0, 0.3) 40%, rgba(0, 0, 0, 0.8) 100%);
    }
  }

.main-section .intro-text {
    position: relative;
    height: 70%;
    transition: all ease-in-out 0.3s;
}

.main-section.user-logged-in .intro-text {
    opacity: 0;
}

.main-section .intro-text h1 {
    font-weight: var(--bold-font-weight);
    font-size: 8vh;
}

.main-section .intro-text h2 {
    font-weight: var(--semi-bold-font-weight);
}

.main-section .intro-text p {
    margin: 0;
}

.your-decks-title {
    padding-left: 1rem;
    font-weight: var(--semi-bold-font-weight);
    font-size: var(--large-text-size);
}

















