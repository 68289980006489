:root {
    --topBar-BackgroundHeight: 7vh;
}

#DeckEditor {
    /*background: var(--bg-gradient);
    background-color: #6a4e66;*/
    background-color: var(--light-gray-background);
    width: 100vw;
    display: flex;
}

#DeckEditor * {
    box-sizing: border-box !important;
}

#DeckEditor .info-message {
    font-size: var(--large-text-size);
    font-weight: var(--bold-font-weight);
    width: 100%;
    position: fixed;
    top: 30%;
}

/*---------- SIDE PANEL ----------*/

.side-panel {
    --side-panel-width: 45vh;
    --img-width: calc(var(--side-panel-width) - 4vh);
    min-width: var(--side-panel-width);
    z-index: 100;
}

.side-panel .card-panel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1vh;
}

.side-panel .card-panel .add-row {
    display: flex;
    justify-content: center;
    align-items: center;
}

.side-panel .card-panel .add-row div {
    border-radius: 10vh;
    margin: 1.5vh 0.5vh;
    overflow: hidden;
    width: 15vh;
}

.side-panel .card-panel .add-row button {
    margin: 0;
    border: 0;
    background-color: transparent;
    height: 5vh;
    width: 100%;
    font-weight: var(--semi-bold-font-weight);
    font-size: var(--small-text-size);
}

.side-panel .card-panel .add-row button:hover {
    background-color: rgba(128, 128, 128, 0.473);
}

.side-panel .card-panel .add-row-extended p {
    font-size: 2vh;
    margin: 0;
}

.side-panel .card-panel .add-row-extended h1 {
    font-size: 2.5vh;
    margin: 0;
}

.side-panel .card-panel .interaction {
    display: flex;
    flex-direction: column;
    text-align: justify;
    margin-left: 1vh;
}

.side-panel .card-panel .interaction>div {
    margin-top: 2vh;
}

.side-panel .card-panel .interaction>div>div {
    display: flex;
    justify-content: space-around;
}

.side-panel .card-panel .interaction label {
    font-size: 2vh;
}

.side-panel .card-panel .interaction h1 {
    font-size: 2vh;
}

.side-panel .card-panel .interaction .ps-4 {
    border-left: 1px solid white;
    margin-bottom: 1vh;
}


.card-editor-panel {
    margin-top: 0vh;
    margin-left: auto;
    margin-right: auto;
    flex-shrink: 0;
    min-height: calc(var(--img-width) * 1.39);
    min-width: var(--img-width);
    height: calc(var(--img-width) * 1.39);
    width: var(--img-width);
    background-color: transparent;
    overflow: visible;
    padding: 0;
    border: 0;
    padding-bottom: 0 !important;
}

.card-editor-panel .card-image {
    min-height: calc(var(--img-width) * 1.39);
    min-width: var(--img-width);
    object-fit: cover;
    border-radius: 1.8vh;
}

.side-panel .focused-card {
    position: absolute;
    top: 0vh;
    left: 0vh;
    width: var(--side-panel-width);
    /*background-color: var(--light-gray-background);*/
    backdrop-filter: blur(0.7vh);
    min-height: 100vh;
}

.side-panel .focused-card img {
    height: calc(var(--img-width) * 1.39);
    width: var(--img-width);
    margin-left: 2vh;
    margin-right: auto;
    margin-top: 3vh;
    border-radius: 2vh;
}

.side-panel .focused-card * {
    transition: all 0s !important;
}

.side-panel .focused-card .card-back-button {
    display: none;
}

.side-panel .return-button {
    background-color: var(--black-transparent-background) !important;
}

#DeckEditor .search-group {
    padding: 0.5vh;
}

#DeckEditor .search-panel {
    height: 100dvh;
    display: flex;
    flex-direction: column;
}

#DeckEditor .search-results {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 100%;
    padding-right: 1vh;
}

.search-results h3 {
    position: absolute;
    margin: 2vh;
    font-size: var(--small-text-size);
    font-weight: var(--regular-font-weight);
}

.search-results .scroll-container {
    height: 90vh;
    height: 90dvh;
    overflow: hidden;
}

.search-result {
    position: relative;
    background-color: transparent;
    border: 0;
    color: rgb(230, 230, 230);
    margin-bottom: 0.4vh;
    height: 6vh;
    align-items: center;
    padding-right: 0;
    padding-left: 0;
    flex-shrink: 0;
    overflow: hidden;
    width: 100%;
}

.search-result:last-child {
    margin-bottom: 0;
}

.result-image {
    padding-right: 70%;
    object-fit: cover;
    transition: all 0.3s;
    mask-image: linear-gradient(to left, transparent 70%, rgba(0, 0, 0, 1) 80%, rgba(0, 0, 0, 1) 100%);
}

.search-result:hover .result-image {
    padding-right: 50%;
    mask-image: linear-gradient(to left, transparent 50%, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 1) 100%);
}

.search-result>div {
    background-color: rgba(39, 23, 39, 0.61);
}

.search-result>div,
.result-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 6vh;
}

.search-result:hover {
    filter: brightness(60%);
}

.search-result:last-child {
    border-bottom: 0;
}

.search-result p {
    font-size: 2vh;
    margin: 0;
    white-space: normal;
    text-align: start;
    overflow: hidden;
    text-overflow: ellipsis;
    text-shadow: 0px 0px 8px black;
    font-weight: var(--semi-bold-font-weight);
}

.mana-cost {
    display: flex;
    margin-right: 1vh;
}

.mana-cost img {
    width: 2vh;
    height: 2vh;
    object-fit: cover;
    margin: 0.15vh
}

/*---------- DECK LIST ----------*/

#DeckEditor .main {
    width: 100%;
    display: flex;
    flex-direction: column;
}

#DeckEditor .main .topBar {
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 20;
}
/*
#DeckEditor .main .topBar:after {
    content: '';
    width: 100%;
    height: 4dvh;
    background: linear-gradient(180deg, var(--light-gray-background) 80%, transparent 92%);
    position: fixed;
    top: var(--topBar-BackgroundHeight);
    z-index: -1;
}
*/
#DeckEditor .main .topBar .default-button {
    margin: 0;
}

#DeckEditor .main .topBar p {
    margin: 0;
}

#DeckEditor #Save-button:disabled,
#DeckEditor #Save-button[disabled] {
    display: none;
    pointer-events: none;
}

#DeckName {
    border: 0;
    background-color: transparent;
    color: white;
    font-size: 6.5vh;
    width: 100%;
    font-weight: var(--bold-font-weight);
    padding: 0;
    text-align: start;
}

#DeckName.disabled {
    pointer-events: none;
}

#DeckEditor .main .topBar .row1 {
    display: flex;
    /*background-color: rgb(11, 13, 15);*/
    background-color: var(--light-gray-background);
    border-bottom: 1px solid rgba(255, 255, 255, 0.233);
}

#DeckEditor .main .topBar .row1 button {
    background-color: transparent;
    font-size: var(--small-text-size);
    font-weight: var(--semi-bold-font-weight);
    border: 0;
    color: rgb(187, 187, 187);
    text-align: center;
    border-bottom: 0.6vh solid transparent;
    transition: all 0.5s;
    height: var(--topBar-BackgroundHeight);
    padding-top: 0.6vh;
}

#DeckEditor .main .topBar .row1 button.selected {
    /*
    color: var(--lighter-main-purple);
    border-bottom: 0.6vh solid var(--lighter-main-purple);*/
    color: white;
    border-bottom: 0.6vh solid white;
}

#DeckEditor .main .decklist-top-row {
    display: flex;
    justify-content: space-between;
    font-size: 2vh;
    font-weight: var(--regular-font-weight);
    opacity: 0.8;
    padding-bottom: 1vh;
}

#DeckEditor .main .topBar .topBar-left-section {
    padding-top: 1vh;
    padding-bottom: 1vh;
    width: 38vh;
    display: flex;
    height: calc(var(--topBar-BackgroundHeight) - 2vh)
}

#DeckEditor .main .topBar .topBar-left-section button {
    margin: 0 0.2vh;
}

#DeckEditor .main .deckList {
    position: relative;
    min-height: calc(100dvh - var(--topBar-BackgroundHeight));
    --card-width: 28.6vh;
    --card-height: calc(var(--card-width) * 1.39);
    --grid-gap: 1vh;
}

#DeckEditor .main .deckList.small-grid {
    --card-width: 18.6vh;
    --card-height: calc(var(--card-width) * 1.39);
    --grid-gap: 0vh !important;
}

.deckList_hordeDeck {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    width: 100%;
}

.deckList_hordeDeck>div:last-child {
    padding-bottom: 6vh;
}

.deckList_hordeDeck>div {
    width: 100%;
    /*display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30.8vh, 1fr));
    grid-auto-rows: 42vh;*/
    display: grid;
    grid-gap: var(--grid-gap);
    gap: var(--grid-gap);
    justify-items: center;
    grid-template-columns: repeat(auto-fill, minmax(var(--card-width), 1fr));
    padding-left: 1vh;
    padding-right: 1vh;
}

/* Stack */

.deckList.stacks .deckList_hordeDeck>div>button {
    height: 6vh !important;
}

.deckList.stacks .deckList_hordeDeck>div>button:last-child {
    height: calc(var(--card-height)) !important;
}

.deckList_hordeDeck>div .cardCount {
    right: 0 !important;
    left: auto !important;
    top: 0 !important;
    font-size: 3vh !important;
    background-color: var(--black-transparent-background);
    padding: 0 1.5vh;
    transform: translate(0, 0%) !important;
    border-bottom-left-radius: var(--UI-elements-border-radius);
    border-top-right-radius: 1.5vh;
}



.deckList_hordeDeck>div .card {
    margin: 0.5vh auto !important;
}

.deckList_hordeDeck>p {
    font-size: var(--small-text-size);
    font-weight: var(--semi-bold-font-weight);
    text-align: start;
    padding-left: 3vh;
}

.deckList .card {
    max-height: 40vh;
}

#DeckEditor .small-grid .card>.flashback-icon {
    width: 3.5vh !important;
}

#DeckEditor .small-grid .card .card-back-button .default-button {
    width: 6.5vh !important;
}

#DeckEditor .card>.flashback-icon {
    width: 4.5vh !important;
    height: fit-content !important;
    padding: 0.4vh;
    position: absolute;
    top: 0.25vh;
    right: 50%;
    transform: translateX(50%);
    border-radius: 5vh !important;
}

.reprint-picker {
    width: 80vw;
    height: 90vh;
    height: 90dvh;
    position: fixed;
    top: 10vh;
    top: 10dvh;
    margin-left: 10vw;
    overflow-y: auto;
    overflow-x: hidden;
}

.card-reprint {
    flex-shrink: 0;
    height: 45vh;
    width: 32.6vh;
    border-radius: 1.5vh;
    padding: 0;
    border: 0;
    margin: 1.5vh;
    background-color: transparent;
}

.card-reprint img {
    height: 45vh;
    width: 32.6vh;
    object-fit: cover;
    border-radius: 1.5vh;
}

.deck-list-panel textarea,
.deckEditor-init textarea {
    height: 60vh;
    width: 100%;
}

/*---------------------- DECK INFO ----------------------*/

#DeckEditor .deck-info-toggle {
    width: 4vh;
    min-width: 4vh;
    height: 100%;
}

#DeckEditor .deck-info-toggle img {
    width: 3vh;
    height: 3vh;
    margin-bottom: 0.3vh;
}

#DeckEditor .deck-info-panel {
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
}

#DeckEditor .deck-info-panel span {
    display: flex;
    flex-flow: row-reverse;
    justify-content: center;
    width: 100%;
}

#DeckEditor .deck-info-panel span div {
    display: flex;
}

#DeckEditor .deck-info-panel span img {
    width: 2.5vh;
    height: 2.5vh;
    margin-left: 1vh;
    margin-top: 1vh;
}

#DeckEditor .deck-info-panel span p {
    font-size: 2vh;
    margin: 1vh;
}

#DeckEditor .deck-info-panel textarea {
    box-sizing: border-box !important;
    color: white;
    font-size: var(--small-text-size) !important;
    font-weight: var(--regular-font-weight);
}

#DeckEditor #Decklist-button {
    height: 4vh;
}

#DeckEditor .intro-and-rules p {
    text-align: start;
    overflow-wrap: anywhere;
    white-space: pre-line;
    margin-top: 0;
}

#DeckEditor .intro-and-rules h2 {
    margin: 2vh 0;
    text-align: start;
}

.image-picker-box {
    display: grid
}

.image-picker-box>* {
    grid-column: 1;
    grid-row: 1;
}

.image-picker-box>p {
    position: relative;
    top: 18vh;
    pointer-events: none;
}

.image-picker-box>h2 {
    position: relative;
    top: 1.8vh;
    right: 1vh;
    text-align: end;
    font-size: var(--large-text-size);
    pointer-events: none;
    text-shadow: 1px 1px 15px black;
}

.image-picker-box>h2>img {
    width: 3vh;
    height: 3vh;
    padding-left: 1vh;
    padding-bottom: 0.7vh;
    filter: drop-shadow(1px 1px 15px black);
}

.image-picker-box .deck-image {
    width: 100%;
    height: 25vh;
    object-fit: cover;
    -webkit-mask-image: linear-gradient(0deg, transparent 0%, #000 25%);
    z-index: 0;
}

.image-picker {
    overflow-y: scroll;
    height: calc(87dvh - 0.5rem);
}

.image-picker .deck-image {
    height: 20vh;
    object-fit: cover;
    width: 30vh;
}

.image-picker>button {
    padding: 0;
    border: 0;
    border-radius: var(--UI-elements-border-radius);
    overflow: hidden;
    margin: 2vh;
}

.deck-info-interaction {
    padding: 0 1vh;
}






/*----------- CUSTOM CARD -----------*/

#DeckEditor .custom-card-toggle img {
    width: 3vh;
    height: 3vh;
    margin-bottom: 0.3vh;
}

.custom-image-panel .ps-3 {
    border-left: 1px solid white;
}

.custom-image-panel .card {
    margin-top: 8vh;
}

.custom-image-panel h2 {
    margin-bottom: 2.5vh;
}

.custom-image-panel .form-control {
    color: white;
    font-size: var(--small-text-size);
    border: 0 solid transparent !important;
}

#DeckEditor .fullscreen-blurred-background .big-button {
    position: fixed;
    left: 4vh;
    top: 4vh;
    width: 10vh;
    z-index: 10000;
}

.custom-image-panel .form-check {
    margin-right: 0;
    margin-bottom: 0.5vh;
    text-align: left;
    display: flex;
    align-items: center;
}

.custom-image-panel input[type="radio"] {
    margin-top: 1.2vh;
}

.not-lategame {
    filter: grayscale(100%) brightness(0.5);
}

.like-row {
    height: 5vh;
}

.like-row button {
    margin-top: 0;
    margin-bottom: 0;
}

#DeckEditor .patreon-0 {
    text-shadow: none;
}















/* NEW */

#DeckEditor .deck-intro {
    height: 40vh;
    width: 100%;
    background-color: black;
    overflow: hidden;
}

#DeckEditor .deck-intro .background {
    object-fit: cover;
    object-position: 50% 10%;
    position: absolute;
    top: 0;
    left: 0;
    height: 40vh;
}

#DeckEditor .deck-intro .deck-info {
    backdrop-filter: blur(2.99vh);
    width: 100%;
    padding: 2vh;
    padding-top: 8vh;
    background-color: #22222249;
}


@media screen and (max-width: 1200px) {
    #DeckEditor .deck-intro .deck-info {
        padding-top: calc(2vh + 2.5rem);
    }
}

#DeckEditor .deck-intro .deck-art {
    object-fit: cover;
    width: 50vh;
    height: 100%;
    z-index: 5;
    margin-bottom: auto;
    margin-top: auto;
}

#DeckEditor .deck-intro .deck-art-edit {
    position: absolute;
    left: 5vh;
    top: 10vh;
    width: 5vh;
    height: 5vh;
}

.deck-title-section {
    padding: 0.5vh;
}

#DeckEditor .deck-info .art-section {
    border: 0.5vh solid transparent;
    margin-right: 2vh;
}

#DeckEditor .deck-info .art-section.editable:hover {
    border: 0.5vh solid white;
}

#DeckEditor .intro-and-rules {
    overflow-y: scroll;
}

#DeckEditor .intro-and-rules form{
    padding: 1vh;
}

#DeckEditor .deck-intro .patreon {
    margin: 0;
    pointer-events: none;
}

#DeckEditor .button-row {
    min-height: 5vh;
    background-color: var(--gray-background);
}

#DeckEditor .side-panel {
    position: sticky;
    top: 0;
}

#DeckEditor .topBar {
    position: sticky;
    top: 0;
}

#DeckEditor .custom-card-toggle.default-button,
#DeckEditor .button-row .default-button {
    background-color: rgba(128, 128, 128, 0.233) !important;
    color: white;
    border-radius: 10vh;
    height: 5vh;
    min-width: 11vh;
    /*border: 1px solid white*/
}

#DeckEditor .button-row .default-button.purpleOutline {
    background-color: white !important;
    color: black;
    border-radius: 10vh;
    height: 5vh;
    min-width: 11vh;
    border: 1px solid white
}

/*---- CARD ----*/

#DeckEditor .card {
    height: var(--card-height);
    width: var(--card-width);
    border-radius: 1.5vh !important;
    border: 0 !important;
    background-color: transparent !important;
    overflow: visible;
    padding: 0;
    border: 0;
    margin: 2.5vh 0.5vh;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
}

#DeckEditor .card img,
#DeckEditor .card .foil-effect {
    height: var(--card-height);
    width: var(--card-width);
    object-fit: cover;
    border-radius: 1.5vh !important;
}

#DeckEditor .cardCount {
    position: absolute;
    top: 50%;
    left: 50%;
    color: white;
    transform: translate(-50%, -50%);
    font-weight: var(--bold-font-weight);
    font-size: 6vh;
    text-shadow: 1px 1px 15px black;
    pointer-events: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    margin: 0;
    height: 4vh;
    align-items: center;
    justify-content: center;
    display: flex;
}

@media only screen and (orientation:portrait) {
    #DeckEditor .card {
        height: calc(80vw * 1.39);
        width: 80vw !important;
        border-radius: 5vw !important;
    }
    
    #DeckEditor .card div img,
    #DeckEditor .card .foil-effect {
        height: calc(80vw * 1.39);
        width: 80vw !important;
        border-radius: 5vw !important;
    }

    #DeckEditor .card div .card-back-button img {
        width: 4vh !important;
    }

    #DeckEditor .card .flashback-icon {
        top: 28vh;
    }

    #DeckEditor .card .cantAttackOrBlock {
        margin-top: 4.5vh;
    }

    #DeckEditor .deckList_hordeDeck>div {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(calc(80vw + 1vh), 1fr));
        grid-auto-rows: calc(80vw * 1.39 + 2vh);
    }

    #DeckEditor .deckList .card {
        max-height: calc(80vw * 1.39);
    }

    .art-section {
        display: none;
    }
}

.card-type-select {
    border: 0 !important;
    font-size: var(--small-text-size) !important;
    margin-top: 1.5vh;
}

.zoomed-card {
    width: 50vh;
    height: calc(50vh * 1.39);
    transform: translate(30%, -50%);
    pointer-events: none;
    border-radius: 3vh;
    z-index: 1000000;
}

.zoomed-card.other-side {
    transform: translate(-120%, -50%);
}

.zoomed-card img {
    width: 50vh;
    height: calc(50vh * 1.39);
    border-radius: 3vh;
}