.pop-up-menu {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1000;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none;
    transition: all ease-in-out 0.15s;
}

.pop-up-background {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    opacity: 0;
}

.pop-up-menu>.default-button {
    display: grid;
	align-items: center;
    opacity: 0;
}

.pop-up-menu>.default-button:hover {
    opacity: 1;
}

#GameReborn .board .card:hover .pop-up-menu>.default-button {
    opacity: 1;
}

.pop-up-menu>.default-button>p {
    font-size: 2.5vh;
    pointer-events: none;
    padding-bottom: 0.2vh;
    
}

.pop-up-menu .default-button {
    width: 5vh !important;
    min-width: 5vh !important;
    margin-left: auto;
}

.pop-up-menu .enabled {
    opacity: 1 !important;
}

.pop-up-menu .disabled {
    opacity: 0;
    pointer-events: none;
}

.pop-up-menu-buttons {
    padding: 0.8vh;
    position: absolute;
    border-radius: var(--Main-UI-border-radius);
    width: 20vh;
}

.pop-up-menu-buttons .spacer {
    min-height: 1.8vh;
}

.pop-up-menu-button {
    width: 100%;
}

.pop-up-menu-button:hover {
    background-color: white;
    border-radius: var(--Main-UI-border-radius);
}

.pop-up-menu-button:hover p {
    color: black;
}

.pop-up-menu-button p,
.pop-up-menu-button img {
    margin: 0.5vh;
    margin-left: 1vh;
    margin-right: 1vh;
}

.pop-up-menu-button:hover img {
    filter: brightness(0);
}

.pop-up-menu-button img {
    max-height: 3vh !important;
    max-width: 3vh !important;
}