#DeckBrowser .main {
    background-color: var(--light-gray-background);
    padding-top: 10vh;
    min-height: 100dvh;
}

#DeckBrowser *,
#DeckBrowser::after,
#DeckBrowser::before {
    box-sizing: border-box !important;
}

#DeckBrowser .main h1,
#DeckBrowser .main h2 {
    display: inline;
}

#DeckBrowser .home-title {
    text-align: left;
    margin-top: 1vh;
    margin-bottom: 1.5vh;
}

#DeckBrowser .home-title h1 {
    font-size: 4vh;
    font-weight: var(--semi-bold-font-weight);
}

#DeckBrowser .home-title p {
    margin-bottom: 0;
}

#DeckBrowser .row-title {
    width: 100%;
    text-align: left;
    margin-top: 4vh;
    margin-bottom: 2.5vh;
}

#DeckBrowser .row-title h2 {
    margin-bottom: 0 !important;
}

#DeckBrowser .row-title>* {
    width: auto;
}

#DeckBrowser .row-title a {
    text-decoration: none;
    color: var(--lighter-main-purple);
    font-size: var(--small-text-size);
    font-weight: var(--semi-bold-font-weight);
    margin-left: 4vw;
    margin-top: 0.3vh;
}

/*---------------------- DECK ----------------------*/

#DeckBrowser .deck,
#CollectionManager .deck {
    position: relative;
    height: var(--deck-picker-height);
    border-radius: var(--Main-UI-border-radius);
    margin-bottom: 3vh;
    padding: 0;
    background-color: transparent;
    color: white;
    width: 100%;
    display: block;
    overflow: hidden;
}

#DeckBrowser .deck:hover,
#CollectionManager .deck:hover {
    /*background-color: white;*/
}

#DeckBrowser .deck>img,
#CollectionManager .deck>img {
    border-radius: var(--Main-UI-border-radius);
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

#DeckBrowser .deck .deck-info,
#CollectionManager .deck .deck-info {
    position: absolute;
    z-index: 10;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 1vh;
}

#DeckBrowser .deck h1,
#CollectionManager .deck h1  {
    font-size: 2.8vh;
    z-index: 1;
    margin-bottom: 0;
    font-weight: var(--bold-font-weight);
    text-shadow: 1px 1px 15px black;
}

#DeckBrowser .deck .deck-info p,
#CollectionManager .deck .deck-info p {
    margin: 0;
}

#DeckBrowser .deck .deck-info p.patreon-0,
#CollectionManager .deck .deck-info p.patreon-0 {
    opacity: 0.8;
}


#DeckBrowser .deck>span,
#CollectionManager .deck>span {
    position: absolute;
    display: flex;
    flex-flow: row-reverse;
    width: 100%;
    font-size: 2.5vh;
    height: 5vh;
    bottom: 0;
    z-index: 10;
}

#DeckBrowser .deck>span div,
#CollectionManager .deck>span div {
    display: flex;
}

#DeckBrowser .deck>span img,
#CollectionManager .deck>span img {
    width: max(12px, 2vh);
    height: max(12px, 2vh);
    margin-right: 1.5vh;
    margin-top: 0.9vh;
}

#DeckBrowser .deck>span p,
#CollectionManager .deck>span p {
    font-size: var(--small-text-size);
    font-weight: var(--semi-bold-font-weight);
    margin-top: 0.5vh;
    margin-right: 1vh;
    text-shadow: 1px 1px 15px black;
}

#DeckBrowser .see-all {
    margin-bottom: 2vh;
    display: block;
    height: 23vh;
}

#DeckBrowser .see-all h1 {
    position: absolute;
    right: 2vh;
    bottom: 1.2vh;
}

#DeckBrowser .see-all a {
    color: white;
    text-decoration: none;
    display: block;
    width: 100%;
    height: 100%;
}

.deckVignette {
    position: absolute;
    width: 100%;
    height: 100%;
}

#DeckBrowser .deck>.blurred-background,
#CollectionManager .deck>.blurred-background {
    width: 110%;
    height: 7vh;
    position: relative;
    bottom: calc(6vh - var(--deck-picker-height));
    left: -5%;
    background: none !important;
    z-index: 1;
}