.scroll-wrapper button:first-child {
    margin-top: 0;
}

.scroll-wrapper button:last-child {
    margin-bottom: 2vh;
}

.deckPicker {
    float: left;
    height: 22vh;
    text-align: center;
    display: flex;
    align-items: center;
    padding: 0;
    border: 0;
    width: 100%;
    border-radius: var(--Main-UI-border-radius);
    overflow: hidden;
    margin: 1vh 0;
    background-color: transparent;
    box-sizing: border-box;
    box-shadow: 0 0 0 0.3vh transparent;
}

.deck-hover-animated:hover {
    background-color: white;
    box-shadow: 0 0 0 0.3vh white;
}

.deckPicker .icon {
    position: absolute;
    width: 5vh;
    height: 5vh;
    top: calc(50% - 2.5vh);
    left: calc(50% - 2.5vh);
    color: black;
}
/*
.deckPicker .background {
    filter: brightness(30%);
}
*/
.deckPicker>img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: 50% 0%;
    overflow: hidden;
}

.deck-hover-animated>img {
    transition: all 0.25s ease-in-out;
}

.deck-hover-animated:hover>img {
    transform: scale(1.15);
    filter: brightness(60%);
}

.deckPicker {
    display: inline-block;
    position: relative;
    text-align: center;
    color: white;
}

.deckPicker>div {
    position: absolute;
    left: 0;
    bottom: 0;
    width: calc(100% - 2vh);
    display: flex;
    text-align: start;
    padding: 1vh;
}

.deckPicker p {
    font-weight: var(--bold-font-weight);
    font-size: var(--large-text-size);
    text-shadow: 1px 1px 15px black;
    margin-bottom: 0;
}
