.selected-deck * {
    box-sizing: border-box !important;
}

.selected-deck {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background-color: black;
}

.selected-deck .background {
    backdrop-filter: blur(2.5vh);
    background-color: rgba(32, 25, 32, 0.74);
}

.selected-deck .deck-info {
    position: fixed;
    top: 5dvh;
    left: 10vw;
    height: 90dvh;
    width: 80vw;
    
    /*border-radius: var(--UI-elements-border-radius);*/
}

.selected-deck .deck-info .deck-info-container {
    max-height: 90vh;
}

.selected-deck .deck-info .info {
    z-index: 100;
    text-align: start;
    overflow-wrap: anywhere;
    white-space: pre-line;
    max-height: 50vh;
    overflow-y: scroll;
    padding-bottom: 0 !important;
    
}

.selected-deck .background-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    padding: 0;
    object-fit: cover;
    object-position: 50% 0%;
}

.selected-deck .deck-info .deck-art {
    height: 40vh;
    max-width: 60vh;
    padding: 2vh;
    object-fit: cover;
}

.selected-deck h1 {
    font-weight: 800;
    font-size: 10vh;
    text-align: start;
}

.playable-component-invisible {
    opacity: 0 !important;
}


.deck-panel {
    opacity: 1;
    z-index: 99999999;
    position: relative;
    /*transition: opacity 0.3s ease-in-out;*/
}

.deck-panel.invisible {
    opacity: 0;
    
}

.selected-deck .close-xmark {
    left: 0 !important;
}