.half-screen-panel {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    color: #fff;
    background-color: var(--light-gray-background);
    z-index: 50000;
}

.close-xmark{
    position: fixed;
    top: 0;
    left: 0;
    width: 8vh;
    height: 8vh;
    margin: 2vh;
}

.close-xmark:hover {
    opacity: 0.7;
}

.half-screen-panel div img {
    object-fit: cover;
    height: 100%;
}

.half-screen-panel .copyright {
    position: relative;
    bottom: 5vh;
    left: 0;
    width: 100%;
    text-align: center;
    font-weight: var(--semi-bold-font-weight);
}

.half-screen-panel .container {
    overflow-y: scroll;
    height: 100vh;
    max-height: 100dvh;
    padding-right: 12vh !important;
    padding-left: 12vh !important;
    scrollbar-width: none;
}

@media only screen and (orientation:portrait) {
    .half-screen-panel .container {
        overflow-y: scroll;
        height: 100vh;
        margin-top: 12vh !important;
        padding: 3vh !important;
    }
}