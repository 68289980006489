:root {
    --color1: #9F7928;
    --color2: #D1B464;
    --foil-background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/13471/sparkles.gif");
    --space: 6%;
}

/*
.foil-effect {
    filter: grayscale(100%) brightness(200%) saturate(150%) contrast(1000%);
    mix-blend-mode: screen;
    mask-mode: luminance;
}


.foil-effect {
    filter: grayscale(100%) brightness(200%) saturate(150%) contrast(1000%);
    mix-blend-mode: color-burn;
}

.card-stacks img:nth-child(2) {
   mix-blend-mode: screen;
}

.card-stack:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    
    background-image: linear-gradient(115deg,
            transparent 0%,
            rgb(0, 231, 255) 30%,
            rgb(255, 0, 231) 70%,
            transparent 100%);
            background: white;
    background-position: 0% 0%;
    background-repeat: no-repeat;
    background-size: 300% 300%;
    opacity: 1;
    z-index: 1;
    transform: translate3d(0, 0, 0);
    animation: holoGradient 15s ease infinite;
    mix-blend-mode: multiply;
  }

.foil-effectOLD {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-image: linear-gradient(115deg,
            transparent 0%,
            rgb(0, 231, 255) 30%,
            rgb(255, 0, 231) 70%,
            transparent 100%);
    background-position: 0% 0%;
    background-repeat: no-repeat;
    background-size: 300% 300%;
    mix-blend-mode: color-dodge;
    opacity: 0;
    z-index: 1;
    transform: translate3d(0, 0, 0);
    animation: holoGradient 2s ease infinite;
}

.card-stack {
    background-position: 0% 0%;
    background-size: 300% 300%;
    background: linear-gradient(60deg, rgba(255,0,0,0.75) 0%, rgba(255,154,0,0.75) 10%, rgba(208,222,33,0.75) 20%, rgba(79,220,74,0.75) 30%, rgba(255,255,255,0.75) 35%, rgba(63,218,216,0.75) 40%, rgba(47,201,226,0.75) 50%, rgba(28,127,238,0.75) 60%, rgba(255,255,255,0.75) 65%, rgba(95,21,242,0.75) 70%, rgba(186,12,248,0.75) 80%, rgba(251,7,217,0.75) 90%, rgba(255,0,0,0.75) 100%);
    background: linear-gradient(115deg,
            transparent 0%,
            #9F7928 25%,
            transparent 47%,
            transparent 53%,
            #D1B464 75%,
            transparent 100%);

    background-image:
        url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/13471/sparkles.gif")
        url(''),
        url(''),
        repeating-linear-gradient(82deg,
            rgb(219, 204, 86) calc(var(--space)*1),
            rgb(121, 199, 58) calc(var(--space)*2),
            rgb(58, 192, 183) calc(var(--space)*3),
            rgb(71, 98, 207) calc(var(--space)*4),
            rgb(170, 69, 209) calc(var(--space)*5),
            rgb(255, 90, 180) calc(var(--space)*6),
            rgb(255, 90, 180) calc(var(--space)*7),
            rgb(170, 69, 209) calc(var(--space)*8),
            rgb(71, 98, 207) calc(var(--space)*9),
            rgb(58, 192, 183) calc(var(--space)*10),
            rgb(121, 199, 58) calc(var(--space)*11),
            rgb(219, 204, 86) calc(var(--space)*12)),
        radial-gradient(farthest-corner circle at 50% 50%,
            rgba(255, 255, 255, 0.6) 5%,
            rgba(150, 150, 150, .3) 40%,
            rgb(0, 0, 0) 100%);
    ;
    opacity: .5;
    filter: brightness(.5) contrast(1);
    z-index: 1;
    opacity: 0.5;
    transition: none;
    animation: holoSparkle 20s ease infinite;
    animation: holoGradient 12s linear infinite;
    background-blend-mode: screen, hue, hard-light;
    background-blend-mode: screen, hue, hard-light;
    mix-blend-mode: color-dodge;
    filter: brightness(0.75) contrast(2.5) saturate(.75);
}


@keyframes holoGradient {
    0%,
    100% {
        opacity: 0.5;
        background-position: 50% 50%;
        filter: brightness(.5) contrast(1);
    }

    5%,
    9% {
        background-position: 100% 100%;
        opacity: 1;
        filter: brightness(.75) contrast(1.25);
    }

    13%,
    17% {
        background-position: 0% 0%;
        opacity: .88;
    }

    35%,
    39% {
        background-position: 100% 100%;
        opacity: 1;
        filter: brightness(.5) contrast(1);
    }

    55% {
        background-position: 0% 0%;
        opacity: 1;
        filter: brightness(.75) contrast(1.25);
    }
}

@keyframes holoSparkle {
    0% {
        opacity: 0;
    }

    12% {
        opacity: 1;
    }

    70% {
        opacity: 0.5;
    }

    95% {
        opacity: 0.2;
    }
}

@keyframes holoGradient {
    3% {
        opacity: 0.2;
    }

    5% {
        background-position: 0% 0%;
    }

    7% {
        opacity: 0.5;
    }

    9% {
        background-position: 100% 100%;
    }

    11% {
        opacity: 0.2;
    }

    50% {
        opacity: 0.2;
        background-position: 100% 100%;
    }

    55% {
        opacity: 0.3;
    }

    70% {
        opacity: 0.2;
        background-position: 0% 0%;
    }
}
https://codepen.io/daniel-ruthless-stone/pen/RwNXjvj
*/
.card-stacks {
    display: grid !important;
    transform-origin: center;
}

.card-stacks img,
.card-stacks div {
    grid-column: 1;
    grid-row: 1;
}

.foil-effect {
    content: "";

    pointer-events: none;
    background-position: 70% 70%;
    background-repeat: no-repeat;
    background-size: 300% 300%;
    /*mix-blend-mode: color-dodge;*/
    mix-blend-mode: screen;
    opacity: 0.5;
    z-index: 0;
    animation: none;
    transition: background-position 1s ease;
    background-image: linear-gradient(115deg,
            transparent 0%,
            transparent 25%,
            rgba(0, 231, 255, 0.7) 45%,
            rgba(255, 0, 231, 0.7) 55%,
            transparent 70%,
            transparent 100%);
    /*animation: holoGradient 15s ease infinite;*/
    /*
    background-image: linear-gradient(
      115deg,
      transparent 0%,
      transparent 25%,
      var(--color1) 45%,
      var(--color2) 55%,
      transparent 70%,
      transparent 100%
    );*/
    /*mask-image: url('https://img.freepik.com/free-vector/wavy-smooth-lines-pattern-background_1017-14204.jpg?w=1060&t=st=1692097428~exp=1692098028~hmac=7e64b5150ee3f8623aca7969e569fbe4e39d2e0ae10b0b996970a6d377b75c32'); */
}

.foil-effect-mask-new {
    filter: grayscale(100%) brightness(200%) hue-rotate(31deg) saturate(136%) contrast(1000%) invert(00%);
    opacity: 1;
    z-index: 2;
    mix-blend-mode: screen;
}

.foil-effect-new {
    content: "";

    pointer-events: none;
    background-position: 70% 70%;
    background-repeat: no-repeat;
    background-size: 300% 300%;
    /*mix-blend-mode: color-dodge;*/
    mix-blend-mode: multiply;
    opacity: 0.5;
    z-index: 0;
    animation: none;
    transition: background-position 1s ease;
    background-image: linear-gradient(115deg,
            white 0%,
            white 25%,
            rgba(0, 231, 255, 0.7) 45%,
            rgba(255, 0, 231, 0.7) 55%,
            white 70%,
            white 100%);
   filter: brightness(200%);
            
}

.gold {
    /*
    background-image: linear-gradient(115deg,
    transparent 0%,
    #9F7928 25%,
    transparent 47%,
    transparent 53%,
    #D1B464 75%,
    transparent 100%) !important;*/
    background-image: linear-gradient(115deg,
    transparent 0%,
    transparent 25%,
    #9F7928 45%,
    #D1B464 55%,
    transparent 70%,
    transparent 100%) !important;
    opacity: 0.2 !important;
}

.foil-overlay {
    mask-image: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/3/sun.svg");
    mask-composite: substract;
    mask-size: 50%;
    mask-repeat: no-repeat;
    mask-position: 50% 25%;
}

@keyframes holoGradient {

    0%,
    100% {
        background-position: 50% 50%;
    }

    5%,
    9% {
        background-position: 100% 100%;
    }

    13%,
    17% {
        background-position: 0% 0%;
    }

    35%,
    39% {
        background-position: 100% 100%;
    }

    55% {
        background-position: 0% 0%;
    }
}

.cardsToCast .card .shadowed {
    border-radius: 1.7vh;
}